<template>
  <section>
    <v-row class="fill-height">
      <v-col>
        <v-btn
          color="primary"
          :to="`/site/sitelocation/create/${selectedSite.siteId}`"
        >
          <v-icon left>mdi-plus-thick</v-icon>New Site Location
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          return-object
          @change="handleGetSiteLocations"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>apps</v-icon>
              </v-btn>
            </template>
            <span>Show Card View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>list_alt</v-icon>
              </v-btn>
            </template>
            <span>Show List View</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="noSiteLocations">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800;"
      >
        No {{ typeName }} configured
      </v-card>
    </v-row>
    <confirm-dialog ref="confirmDelete" title="Delete Site Location?"></confirm-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredSiteLocations"
      class="elevation-1"
      :options="defaultOptions"
      v-if="filteredSiteLocations.length > 0"
    >
      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn
            :to="`/site/${selectedSite.siteId}/sitelocation/edit/${item.id}`"
            @click="editItem(item)"
            icon
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>

    </v-data-table>
  </section>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../../_api";
import location_api from "../_api";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  data() {  
    return {
      typeName: "Site Location",
      titleIcon: "whatshot",
      sites: [],
      selectedSite: {},
      filteredSiteLocations: [],
      headers: [
        {
          text: "Site Location Name",
          value: "siteLocationName",
        },      
        { text: "Actions", value: "id", sortable: false },
      ],
      defaultOptions: {
        // sortBy: ["version"],
        // sortDesc: true,
      },
    };
  },
  async created() {
    this.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.selectedSite = this.defaultSite;
      this.filteredSiteLocations = await location_api.getSiteLocationsBySiteId(this.selectedSite.siteId);
    }
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),
    toggleView() {},
    editItem() {},

    async handleDelete(siteLocation) {
      const msg = `Are you sure you want to delete ${siteLocation.siteLocationName} and its associated data?  Deleting a site location cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      try {
        await location_api.deleteSiteLocation(siteLocation.id);
        //refresh the collection
        let refreshedSiteLocations = this.filteredSiteLocations.filter(
          (e) => e.id !== siteLocation.id
        );
        this.filteredSiteLocations = refreshedSiteLocations;
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    upload(item) {
      console.log(item);
    },

    async handleGetSiteLocations() {
      this.setDefaultSite(this.selectedSite);
      this.filteredSiteLocations = await api.getEgaugesForSite(
        this.selectedSite.siteId
      );
    },
  },
  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noSiteLocations: function () {
      return this.filteredSiteLocations.length === 0;
    },
  },
};
</script>